<template>
  <section id="triage-rules">
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
    <!-- PAGE -->
    <b-card title="Settings">
      <b-row v-if="triagerule.version.active==1">
        <b-col cols="12">

          <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">
            <h4 class="alert-heading"> WARNING </h4>
            <div class="alert-body">
              <span v-if="getLogin.caneditactiveversion">This triagerule is part of the active version.</span>
              <span v-else>This triagerule is part of the active version. It is not possible to make any changes to this rule.</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Triage rule name"
            label-for="input_triage_rule_name"
            label-cols-md="12"
          >
            <b-form-input
              :disabled="!canEditActiveVersion"
              id="input_triage_rule_name"
              placeholder="Set triage rule name"
              v-model="triagerule.name"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6" v-if="isNew">
          <b-form-group
            label="Condition(s)"
            label-for="input_conditions"
            label-cols-md="12"
          >
            <v-select
              :disabled="!canEditActiveVersion"
              v-model="triagerule.conditions"
              id="input_conditions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="naam"
              
              :options="conditions"
              placeholder="Set condition(s)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <template v-if="isNew">
        <!-- CRITERIA -->
        <!-- <pre>{{triagerule}}</pre> -->

        <Criteria
          v-for="(criteria,index) in triagerule.criteria"
          :criteria="criteria"   
          :id="index"
          :key="index"
          :critKey="index"
          :canEditActiveVersion="canEditActiveVersion"
          :version="triagerule.version"
          @setActivePart="setActivePart"
          @setActivePartQuestion="setActivePartQuestion"
          @removeAnswer="removeAnswer"
          @removeCriteria="removeCriteria"
          @resetSidebar="resetSidebar"
        >
					<div class="orSeperator text-secondary" v-if="index > 0">
						<h3 class="text-secondary">OR</h3>
					</div>
				</Criteria>        

        <div class="addOrContainer text-secondary">
          <b-button
            v-if="canEditActiveVersion"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="addOr"
          >
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add OR</span>
          </b-button>
        </div>

        <!-- CRITERIA MATCH SETTINGS -->
        <b-row class="mt-2 mt-md-2">
          <b-col md="6">
            <b-card class="extraMarginHalf">
              <h4>If criteria match</h4>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Urgency"
                    label-for="input_match_urgency"
                    label-cols-md="4"
                  >
                    <v-select
                      :disabled="!canEditActiveVersion"
                      id="input_match_urgency"
                      v-model="triagerule.urgencyLevel"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="urgencyLevels"
                      placeholder="Set urgency"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Advice"
                    label-for="input_match_advice"
                    label-cols-md="4"
                  >
                    <v-select
                      :disabled="!canEditActiveVersion"
                      id="input_match_advice"
                      v-model="triagerule.advice"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="advices"
                      placeholder="Set advice"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Exercises"
                    label-for="input_match_advice"
                    label-cols-md="4"
                  >
                    <v-select
                      :disabled="!canEditActiveVersion"
                      id="input_match_exercises"
                      v-model="triagerule.exercise"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="exercises"
                      placeholder="Set exercises"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col md="6">
            <b-card class="extraMarginHalf">
              <h4>Else</h4>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Urgency"
                    label-for="input_match_else_urgency"
                    label-cols-md="4"
                  >
                    <v-select
                      :disabled="!canEditActiveVersion"
                      id="input_match_else_urgency"
                      v-model="triagerule.urgencyLevel_else"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="urgencyLevels"
                      placeholder="Set urgency"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Advice"
                    label-for="input_match_else_advice"
                    label-cols-md="4"
                  >
                    <v-select
                      :disabled="!canEditActiveVersion"
                      id="input_match_else_advice"
                      v-model="triagerule.advice_else"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="advices"
                      placeholder="Set advice"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Exercises"
                    label-for="input_match_else_exercises"
                    label-cols-md="4"
                  >
                    <v-select
                      :disabled="!canEditActiveVersion"
                      id="input_match_else_exercises"
                      v-model="triagerule.exercise_else"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="exercises"
                      placeholder="Set exercises"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!-- /PAGE -->
    </template>

    <b-button
      v-if="canEditActiveVersion"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="saveTriageRule"
    >
      <feather-icon icon="SaveIcon" class="mr-25" />
      <span>Save</span>
    </b-button>

    <!-- SIDEBAR -->
    <b-sidebar
      width="40%"
      right
      shadow
      parent="body"
      default-index="1"
      spacer
      v-model="showSidebar"
      title="Select answers"
      class="sidebar"
    >
      <div class="px-3 py-2">
        <p>Search for the questions/answers you want to add.</p>
        <h6>Search</h6>
        <b-form-group>
          <b-form-input id="inputName" placeholder="Search term(s)" v-model="query"/>

        </b-form-group>
        <b-overlay
          :show="isLoadingQueries"
          rounded="sm"
        >
          <b-form-select
            v-model="questionSearchResultsSelected"
            :options="filteredQueries"
            :select-size="4"
            @change="loadAnswers"
          />
        </b-overlay>

        <b-card class="extraMargin" v-if="questionSearchResultsSelected">


          <h6>{{selectedQuestion.text}}</h6>
          
          <p v-if="selectedQuestion.content_voor" v-html="this.$options.filters.nl2br(selectedQuestion.content_voor)"></p>

          <div v-if="selectedQuestion.image">
            <img :src="selectedQuestion.image" style="margin-bottom:1em; max-width: 100%;"/>
          </div>
          <div v-if="selectedQuestion.youtube">
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="selectedQuestion.youtube"
              allowfullscreen
            />
          </div>

          <p class="extraMargin" v-if="selectedQuestion.content_na">{{selectedQuestion.content_na}}</p>

          <b-overlay
            :show="isLoadingAnswers"
            rounded="sm"
          >
            <div class="checkbox_container">
              <b-form-checkbox
                    v-for="(answer,answerIndex) in answers"
                                
                    
                    :key="answerIndex"
              
                    v-model="selectedAnswerIds"
                    v-bind:value="answer.id"
                    @change="updateAnswerSelection"
                    >{{answer.antwoord}}</b-form-checkbox>

            </div>
          </b-overlay>
        </b-card>
      </div>
    </b-sidebar>
    <!-- /SIDEBAR -->

    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BForm,
  BFormInput,
  BButton,
  BBadge,
  BSidebar,
  BFormCheckbox,
  BEmbed,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Criteria from "./Criteria.vue";

import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { data } from "vue-echarts";

export default {
  data() {
    return {
      query:'',
      activePart:false,
      activeQuestion:false,
      isLoading:true,
      isLoadingQueries:false,
      isLoadingAnswers:false,
      answers:[],
      conditions:[],
      urgencyLevels:[],
      advices:[],
      exercises:[],
      triagerule: {
        version:{},
        name:'rule name',
        conditions:[
            // {id:1, name:'serious condition'},
            // {id:2, name:'good condition'},
        ],
        criteria:[],
        _criteria: [
            {
              parts:[
                {operator:'all', answers:[
                  {id:1, text:"yes", question:{id:5, text:'Does it hurt?'}},
                  {id:2, text:"a lot", question:{id:5, text:'Does it hurt?'}},
                  {id:3, text:"no", question:{id:6, text:'Is it nice?'}},
                ]},
                {operator:'1', answers:[
                  {id:1, text:"yes", question:{id:5, text:'Does it hurt?'}},
                  {id:3, text:"no", question:{id:6, text:'Is it nice?'}},
                ]},
              ]
            }
        ],
        match: {
          advice:{id:1, name:'something'},
          urgencylevel:{id:1, name:'something'},
          exercise:{id:1, name:'something'}
        },
        nomatch: {
          advice:{id:1, name:'something'},
          urgencylevel:{id:1, name:'something'},
          exercise:{id:1, name:'something'}
        },
      },
      ltr:'ltr',
      // optionConditions: [
      //   { title: "All of these answers" },
      //   { title: "None of these answers" },
      //   { title: "At least 1 of these answers" },
      //   { title: "At least 2 of these answers" },
      //   { title: "At least 3 of these answers" },
      // ],
      showSidebar: false,
      nextTodoId: 2,
      checkbox1: ["1", "2", "3"],
      selectedAnswerIds:[],
      questionSearchResultsSelected: false,
      questionSearchResults: [
        // { value: 1, text: "Voer de volgende test uit" },
        // { value: 2, text: "Heeft u last van nachtelijke pijn?" },
        // { value: 3, text: "Kunt u uw elleboog pijnoos strekken?" },
        // { value: 4, text: "Hef uw arm tot 90 graden" },
      ],
      items: [
        {
          id: 1,
          selectedStatement: "All of these answers",
          prevHeight: 0,
        },
      ],
      optionsUrgency: [{ title: "Urgent" }, { title: "Routine" }],
      optionsAdvice: [{ title: "Do this" }, { title: "Do that" }],
      optionsExercises: [
        { title: "Shoulder basic exercises" },
        { title: "Shoulder RSI exercises" },
      ],
      // optionsConditions: [
      //   { value: '1', title: "Osteoarthrosis of the shoulder" },
      //   { value: '2', title: "RSI" },
      //   { value: '3', title: "Shoulder instability" },
      // ],
      conditions:[],
      //selectedConditions: []
    };
  },
  computed: {
    canEditActiveVersion() {
      if (this.getLogin && this.getLogin.caneditactiveversion) return true;
      if (this.triagerule && this.triagerule.version.active) return false;
    },
    isNew() {
      return this.$route.params.id?true:false
    },
    selectedQuestion() {
      if (!this.questionSearchResultsSelected) return {
        text:'Select question first'
      }
      var qs = this.questionSearchResults.filter(q=>{return q.value==this.questionSearchResultsSelected});
      //console.log('filter', qs)

      if (qs[0]) {
        qs[0].id = qs[0].value;
        if (qs[0].content) {
          //if (qs[0].content.substr(0,5)=='https') {
          if (qs[0].content.includes('youtube') || qs[0].content.includes('youtu.be')) {
              qs[0].youtube = qs[0].content

              if (!qs[0].youtube.includes('/embed')) {
                qs[0].youtube=qs[0].youtube.replace('.com/', '.com/embed/');
              }

              qs[0].youtube=qs[0].youtube.replace("watch?v=", "");
            
          }
          else {
              if (qs[0].content.substr(0,5)=='https') {
                qs[0].image = qs[0].content
              }
              else {
                qs[0].image = 'https://www.hierhebikpijn.nl/img/diagnosecheck/'+qs[0].content

              }
          }
        }
      }

      return qs[0];
    },
    filteredQueries() {
        if (!this.query) return this.questionSearchResults;

        return this.questionSearchResults.filter(q=>{return q.text.toLowerCase().includes(this.query.toLowerCase())?true:false})
    },
    selectedConditions() {
      if (!this.triagerule.conditions || !this.triagerule.conditions.length) return []

      var selectedC = [];
      var selectedConditionIds = [];
      this.triagerule.conditions.forEach(c=>{selectedConditionIds.push(c.id)});
      this.conditions.forEach(c=>{
        if (selectedConditionIds.includes(c.id)) {
            selectedC.push(c);
        }
      })
      return selectedC;
    },

  },
  components: {
    Criteria,
    BRow,
    BCol,
    BCard,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BBadge,
    BOverlay,
    vSelect,
    BSidebar,
    BFormCheckbox,
    BEmbed,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods : {
    resetSidebar() {
        this.showSidebar = false;
        this.selectedAnswerIds = [];
    },
    removeAnswer(clickedPart, answerId) {
        console.log('removeAnswer', clickedPart, answerId)
        this.triagerule.criteria.forEach(crit=>{
          crit.parts.forEach(part=>{
            if (part.id==clickedPart.id) {
                part.answerIds = part.answerIds.filter(id=>{return id!=answerId})
                part.answers = part.answers.filter(answer=>{return answer.id!=answerId})

                
                console.log(part.answerIds)
            }
            if (part.id==this.activePart.id) {
              this.selectedAnswerIds = part.answerIds
              
            }
          })
        })
    },
    updateAnswerSelection() {
      
      var addedIds = [];
        // remove unselected
        this.triagerule.criteria.forEach(crit=>{
          crit.parts.forEach(part=>{
            if (part.id==this.activePart.id) {
                    var newAnswers = [];
                    console.log('updateAnswerSelection old:', part.answerIds);
                    console.log('updateAnswerSelection new:', this.selectedAnswerIds);
                    
                    part.answers.forEach(answer=>{
                        if (this.selectedAnswerIds.includes(answer.id)) {
                          
                            newAnswers.push(answer);
                            addedIds.push(answer.id)
                        }
                        else {
                          console.log("remove ", answer.id)
                        }
                    })
                    part.answers = newAnswers;
                    
                    part.answerIds = this.selectedAnswerIds;
                    
                }
            })
        });
        console.log(addedIds,this.selectedAnswerIds)

        // add new
        let newIds = this.selectedAnswerIds.filter(x => !addedIds.includes(x));
        newIds.forEach(newId=>{
            // find answer
            var answer = this.answers.filter(a=>{return a.id==newId});
            console.log("add ", answer[0].id)
            this.triagerule.criteria.forEach((crit,critIndex)=>{
              crit.parts.forEach((part,partIndex)=>{
                if (part.id==this.activePart.id) {

                    part.answers.push({
                      id:answer[0].id,
                      text:answer[0].antwoord,
                      question: {
                        id:this.selectedQuestion.id,
                        text:this.selectedQuestion.text
                      }
                    })
                    part.answerIds = this.selectedAnswerIds;
                }
              })
            })
            //console.log(this.triagerule.criteria)
            
        })


    },
    setQueries(queries) {
        this.questionSearchResults = queries.map(r=>{
          let regex = /(<([^>]+)>)/ig;
          return {
            value:r.id,
            text:r.vraag.replace(regex,""),
            content_voor:r.content_voor,
            content:r.content,
            content_na:r.content_na,
          }
        })
        if (this.activeQuestion) {
          //console.log('activate query', this.activeQuestion)
          this.questionSearchResultsSelected = this.activeQuestion.id
          this.query = '';
          this.loadAnswers();
        }
        console.log(this.questionSearchResults)
    },
    async loadAnswers() {
      if (!this.questionSearchResultsSelected) return;
        this.isLoadingAnswers=true;  
        var url = '/checkanswers/query/'+this.questionSearchResultsSelected;
        const res = await this.callApi('get', url);
        this.answers = res.data.result;
        this.isLoadingAnswers=false;
    },
    async loadAllQueries() {
        this.isLoadingQueries=true;  
        var url = '/checkqueries/condition';
        const res = await this.callApi('post', url,{ids:this.triagerule.conditions.map(c=>{return c.id})});
        this.setQueries(res.data.result);

        this.isLoadingQueries=false;
    },
    async searchQueries() {
        this.isLoadingQueries=true;
        console.log('searchQueries', this.query)
        var url = '/checkqueries/search';
        const res = await this.callApi('post', url,{query:this.query});
        this.setQueries(res.data.result);
        this.isLoadingQueries=false;
        // your code

    },
    searchQueriesTimeout() {
        if (this.query.length>=3) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(this.searchQueries, 800);
        }
    },
    setActivePart(part) {
      if (!this.triagerule.conditions.length) {
        alert('Select one or more conditions first.');
        return;
      }
      this.questionSearchResultsSelected=false;
      this.loadAllQueries();
      this.activePart = part;
      this.activeQuestion = false;
      this.updateAnswerIds()
      this.showSidebar = true;
    },
    setActivePartQuestion(pq) {
      this.questionSearchResultsSelected=false;
      this.loadAllQueries();
      this.activePart = pq.part;
      this.activeQuestion = pq.question;
      this.updateAnswerIds()
      this.showSidebar = true;
    },
    getQuestions(answers) {
      var questions = [];
      answers.forEach((answer)=>{
        if (questions[answer.question.id]) {
          questions[answer.question.id].answers.push(answer);
        }
        else {
          questions[answer.question.id] = {
            answers:[answer],
            text:answer.question.text
          }
        }
      })
      return questions;
    },
    async loadConditions() {
      var url = '/conditions';
      const res = await this.callApi('get', url);
      this.conditions = res.data.result;
      this.updateLoading();
    },
    async loadExercises() {
      var url = '/exercises';
      const res = await this.callApi('get', url);
      this.exercises = res.data.result;
      this.updateLoading();
    },
    async loadAdvices() {
      var url = '/advices';
      const res = await this.callApi('get', url);
      this.advices = res.data.result;
      this.updateLoading();
    },
    async loadUrgencyLevels() {
      var url = '/urgencylevels';
      const res = await this.callApi('get', url);
      this.urgencyLevels = res.data.result;
      this.updateLoading();
    },
    async loadTriageRule(id) {
      this.showSidebar=false;
      var url = '/triagerules/'+id;
      const res = await this.callApi('get', url);
      this.triagerule = res.data.result;
      this.updateAnswerIds();
      this.updateLoading();
      //console.log(this.getLogin.caneditactiveversion)
    },

    updateAnswerIds() {
        var ids = [];
        this.triagerule.criteria.forEach(crit=>{
          crit.parts.forEach(part=>{
            var partAnswerIds = [];
                  
                  part.answers.forEach(answer=>{
                    if (part.id==this.activePart.id) {
                      ids.push(answer.id);
                    }   
                    partAnswerIds.push(answer.id);
                  })
                  part.answerIds = partAnswerIds
          })
            
        });
        this.selectedAnswerIds = ids;
    },
    
    async saveTriageRule() {
      this.showSidebar=false;
      var url = '/triagerules';
      var method = 'post'
      this.isLoading = true;
      if (this.triagerule.id) {
          url = '/triagerules/'+this.triagerule.id
          method = 'put'
      }
        

      const res = await this.callApi(method, url, this.triagerule);

      //console.log(res.data.result.id)
      if (res.data.result.id && method=="post") {
        this.$router.push({name:'triage-rule-id', params:{id:res.data.result.id}})
      }
      else {
        this.loadTriageRule(this.$route.params.id);
      }

    },
    addOr() {
      this.triagerule.criteria.push({
        parts:[]
      })
    },
    removeCriteria(crit) {
      console.log('removeCriteria', crit);
      const index = this.triagerule.criteria.indexOf(crit);
      if (index > -1) { // only splice array when item is found
        this.triagerule.criteria.splice(index, 1); // 2nd parameter means remove one item only
      }
      
    },
    
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    updateLoading() {
      console.log('updateLoading', this.triagerule.criteria.length, this.conditions.length, this.urgencyLevels.length, this.advices.length, this.exercises.length)
      if (this.$route.params.id &&
        this.triagerule.criteria.length &&
        this.conditions.length &&
        this.urgencyLevels.length &&
        this.advices.length &&
        this.exercises.length
      ) {
        this.isLoading = false;
      }
      else if (!this.$route.params.id) {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.loadConditions();
    this.loadExercises();
    this.loadAdvices();
    this.loadUrgencyLevels();
    if (this.$route.params.id) {
      this.loadTriageRule(this.$route.params.id);
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.repeater-form {
  //overflow: hidden;
  transition: 0.35s height;
}

.batch_container .badge {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.checkbox_container .custom-checkbox {
  margin-bottom: 0.5rem;
}

.extraMarginHalf {
  margin-top: 0.5rem;
}

.extraMargin {
  margin-top: 1rem;
}

.addOrContainer {
  text-align: right;
  margin-bottom: 1rem;
}

.orSeperator {
  text-align: center;
  margin-bottom: 2rem;
}
[dir] .vs--searchable.vs--disabled#input_conditions .vs__selected {
  background-color:#7376f0;
}
</style>