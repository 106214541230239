<template>
  <div>
    
    <slot></slot>

    <b-card>

          <h4>Criteria</h4>
          <div>
            <div>
              <b-form
                ref="form"
                :style="{ height: trHeight }"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <!-- Row Loop -->
                <b-row
                  class="mt-2 mt-md-2"
                  v-for="(part,partIndex) in criteria.parts"
                                
                    :id="partIndex"
                    :key="partIndex"
                  ref="row"
                >
                
                  <b-col md="1">
                    <b-badge variant="secondary" style="padding: 0.9rem">
                      <!-- {{ index == 0 ? "IF" : "AND" }} -->
                      {{ partIndex == 0 ? "IF" : "AND" }}
                    </b-badge>
                  </b-col>
                  <!-- <pre>{{part}}</pre> -->
                  <!-- Item Name -->
                  <b-col md="9">
                    <v-select
                        :disabled="!canEditActiveVersion"
                        :reduce="option=>option.value"
                        v-model="part.operator"
                        :options="operatorOptions"
                        :dir="ltr"
                    />
                  </b-col>

                  <!-- Remove Button -->
                  <b-col lg="2" md="2" class="mb-50">
                    <b-button style="width: 100%;"
                      v-if="canEditActiveVersion"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-0"
                      @click="removePart(partIndex)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Delete</span>
                    </b-button>
                  </b-col>

                  <!-- Answers -->
                  <b-col md="1" class="mt-1 mt-md-1"></b-col>

                  <b-col md="9" class="mt-1 mt-md-1">
                    
                    <div v-for="question in getQuestions(part.answers)">
                    <!-- <div v-if="index === 0"> -->
                      <!-- Deze buitenste 'v-if' div mag weg, is namelijk alleen om het voorbeeld bij de eerste (bovenste) weergave te tonen voorbeeld -->
                      <p>
                        <a href="#" @click="setActivePartQuestion({part:part,question:question})"
                          >{{question.text}}</a
                        >
                      </p>
                      <div class="batch_container">
                        <b-badge v-for="(answer,answerIndex) in question.answers" variant="success"
                        :id="answerIndex"
                        :key="answerIndex">
                          {{answer.text}} <feather-icon v-if="canEditActiveVersion" @click="$emit('removeAnswer', part, answer.id)" icon="XIcon"
                        /></b-badge>
                      </div>
                      </div>
                    <!-- </div> -->
                  </b-col>

                  <!-- Select Button -->
                  <b-col lg="2" md="2" class="mb-50">
                    <b-button style="width: 100%;"
                      v-if="canEditActiveVersion"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-success"
                      class="mt-0 mt-md-0"
                      @click="$emit('setActivePart', part)"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>Select</span>
                    </b-button>
                  </b-col>

                  <!-- Answers -->
                  <b-col md="1" class="mt-1 mt-md-1"></b-col>

                  <b-col cols="12" class="mt-2 mt-md-2">
                    <hr />
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <b-button
              v-if="canEditActiveVersion"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addPart"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add New</span>
            </b-button>

            <!-- Remove Button -->
            
              <b-button style="margin-left:1em;"
                v-if="canEditActiveVersion && critKey>0"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"  
                @click="removeCriteria"              
              >
                <feather-icon icon="XIcon" class="mr-25" />
                <span>Delete</span>
              </b-button>
          </div>

    </b-card>

  </div>
</template>


<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BButton,
  BBadge,
  BSidebar,
  BFormCheckbox,
  BEmbed,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";

import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { data } from "vue-echarts";
import { integer } from "vee-validate/dist/rules";

export default {
  emits:['setActivePart','setActivePartQuestion','removeAnswer','resetSidebar','removeCriteria'],
  props: {
    criteria: {type:Object, required:true},
    critKey: {required:false},
    canEditActiveVersion: {required:false},
    version: {type:Object, required:true}
      
  },
  data() {
    return {
        operatorOptions:[
            { label: 'All of these answers', value: 'all' },
            { label: 'None of these answers', value: 'none' },
            { label: 'At least 1 of these answers', value: '1' },
            { label: 'At least 2 of these answers', value: '2' },
            { label: 'At least 3 of these answers', value: '3' },
        ],
        ltr:'ltr',
    };
  },
  computed: {
    isNew() {
      return this.$route.params.id?true:false
    },

  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BBadge,
    vSelect,
    BSidebar,
    BFormCheckbox,
    BEmbed,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods : {
    setActivePartQuestion(data) {
      if(!this.version.active){
        this.$emit('setActivePartQuestion', data);
      }
    },
    // showQuestion(part, question) {
    //     console.log('showQuestion', part, question)
    // },
    getQuestions(answers) {
      var questions = [];
      answers.forEach((answer)=>{

        // check if exists (new method so we can preserve the order)
        var q = questions.filter(q=>{return q.id==answer.question.id});
        if (q.length) {
          q[0].answers.push(answer);
        }
        else {
          questions.push({
            id:answer.question.id,
            answers:[answer],
            text:answer.question.text
          })
        }

        // if (questions[answer.question.id]) {
        //   questions[answer.question.id].answers.push(answer);
        // }
        // else {
        //   questions[answer.question.id] = {
        //     id:answer.question.id,
        //     answers:[answer],
        //     text:answer.question.text
        //   }
        // }
      })
      //console.log('questions:', questions)
      var a = [];
      questions.forEach((q)=>a.push(q))
      return a;
    },
    removeCriteria() {
      this.$emit('removeCriteria', this.criteria);
      //this.criteria = false
    },
    addPart() {
        var id = Date.now()+Math.random(1000,9999);
        this.criteria.parts.push({
            id:id,
            operator:'all',
            answers:[]
        });
        this.$emit("resetSidebar")
    },
    removePart(index) {
        this.criteria.parts.splice(index, 1);
    },
    
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  mounted() {

  }
};
</script>